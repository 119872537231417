<!-- 学生选科管理 -- 学科底线配置-- 编辑 -->
<template>
	<edit-template class="box" @confirm="submit" @cancel="
          $confirm('取消编辑?', '提示', { type: 'warning' })
            .then(res => $router.back())
            .catch(() => {})
        " :cancelRemind="false" confirmText="保存" cancelText="取消">
		
    <el-form style="min-height: calc(100vh - 230rem)" :model="form" :rules="rules" ref="form" class="form" label-width="96rem">
      <div class="title">基础信息</div>
      <el-form-item label="运营位名称" prop="operation_id">
        <el-select disabled="" :popper-append-to-body="false" v-model="form.operation_id">
          <el-option v-for="(item, index) in operationOptions" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="校区" prop="school_id">
        <el-select disabled="" :popper-append-to-body="false" v-model="form.school_id">
          <el-option v-for="(item, index) in schoolOptions" :label="item.school_name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <div class="title">学科底线分数设置</div>
      <el-form-item label="物理" prop="chemical">
        <el-input-number v-model="form.chemical" ref="number" :min="0" :max="150" style="width: 140rem !important" :step="1" :precision="1" step-strictly=""></el-input-number>
        <i class="i1">分</i>
      </el-form-item>
      <el-form-item label="化学" prop="physics">
        <el-input-number v-model="form.physics" ref="number" :min="0" :max="150" style="width: 140rem !important" :step="1" :precision="1" step-strictly=""></el-input-number>
        <i class="i1">分</i>
      </el-form-item>
    </el-form>

    
  
</edit-template>
</template>

<script>
export default {
	_config:{"route":{"path":"edit","meta":{"title":"编辑"}}},
  data() {
    return {
      loading: false,
      form: {
        operation_id: null,
        school_id: null,
        chemical: null,
        physics: null,
      },
      rules: {
        operation_id: [{required: true, message: '请选择运营位名称'}],
        school_id: [{required: true, message: '请选择校区'}],
        chemical: [{required: true, message: '请输入物理学科底线分数'}],
        physics: [{required: true, message: '请输入化学学科底线分数'}],
      },
      schoolOptions: [],
      operationOptions: [],
    }
  },
  created() {
    this.getSchool()
    this.getOperation()
    this.getData()
  },
  methods: {
    getData() {
      this.$_axios2('api/pre-course-select/pc/score-baseline-details?id=' + this.$route.query.id).then(res => {
        let data = res.data.data
        console.log(data)
        this.form = {
          operation_id: data.operation_id,
          school_id: data.school_id,
          chemical: data.values[0].score,
          physics: data.values[1].score,
        }
      })
    },

    getSchool() {
      this.$_axios2('api/pre-course-select/options/schools').then(res => {
        this.schoolOptions = res.data.data || []
      })
    },

    getOperation() {
      this.$_axios2('api/pre-course-select/options/operations').then(res => {
        this.operationOptions = res.data.data || []
      })
    },

    submit() {
      this.$refs.form
          .validate()
          .then(res => {
            let params = {
              id: this.$route.query.id,
              values: [
                {subject_id: '4', score: this.form.chemical},
                {subject_id: '5', score: this.form.physics},
              ]
            }
            this.$_axios2.post('api/pre-course-select/pc/score-baseline-edit', params).then(res => {
              this.loading = true
              if (res.data.status === 0) {
                this.$message.success({
                  message: '保存成功',
                  onClose: () => {
                    this.$store.commit("setPage", 1);
                    this.$router.go(-1)
                  }
                })
              }
            }).finally(() => this.loading = false)
          })
    },

  }
}
</script>

<style lang="scss" scoped>
.box {
  .form {
    font-size: 14rem;
    margin: 0 0 20rem 50rem;

    ::v-deep .el-form-item__error {
      margin-left: 80rem;
    }

    .title {
      font-weight: bold;
      font-size: 16rem;
      padding-top: 35rem;
      margin-bottom: 35rem;
    }

    .el-form-item {
      margin-left: 14rem;
    }

    .subject {
      display: flex;
      align-items: center;

      ::v-deep .el-form-item__error {
        margin-left: 0;
      }

      //padding: 15rem 0rem;
      //justify-content: space-around;
      margin-bottom: 15rem;

      .item_box {
        display: flex;
        align-items: center;
        justify-content: space-around;
        padding: 15rem 10rem;
        flex: 1;
      }

      .item {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 10rem;

        > span {
          margin-right: 15rem;
        }

        .btn {
          border-radius: 50%;
          width: 30rem;
          height: 30rem;
          text-align: center;
          line-height: 30rem;
          cursor: pointer;
          margin-left: 15rem;
          border: 1rem solid #666666;
        }
      }

      .item.btns {
        width: 100rem;
        justify-content: flex-start;
        text-align: left;

        .btn {
          display: inline-block;
        }
      }

      .el-form-item {
        margin: 0;
      }
    }

    @media only screen and (max-width: 1399rem) {
      .subject {
        .item {
          display: block;
          text-align: center;

          > span {
            display: block;
            margin-right: 0;
            margin-bottom: 15rem;
          }

          ::v-deep .el-input {
            width: 180rem !important;
          }
        }

        .btns {
          width: 130rem;
          justify-content: flex-start;
          text-align: left;

          .btn {
            display: inline-block;
          }
        }
      }
    }
  }
}

.i1 {
  margin-left: 16rem;
}
</style>
